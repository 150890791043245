import React, { useState, useEffect } from 'react';
import './css/responsive.css';
import './css/header.css';
import './css/typography.css';


const HeaderSection = ({ scrollToSection }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const handleTitleClick = (section) => {
    scrollToSection(section);
    setMenuOpen(false); // Ferme le menu lorsque un titre est cliqué
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
      if (window.innerWidth >= 900) {
        setMenuOpen(false); // Ferme le menu si l'écran est redimensionné au-dessus de 900px
      }
    };

    const handleScroll = () => {
      if (menuOpen) {
        setMenuOpen(false); // Ferme le menu lors du défilement
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll); // Écoute les événements de défilement
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll); // Nettoie les écouteurs
    };
  }, [menuOpen]);

return (
    <div
      className="background-photo"
      style={{
        backgroundImage: "url('/image/profil2.webp')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        position: 'relative',
      }}
    >
      <div className="fuzzy-overlay"></div>
      <img src="/image/profil2.webp" alt="Preload" style={{ display: 'none' }} loading="eager" />

<div className="white-band">
  <div className="text-left">
  <h1 className="header-title">Valentin Leverrier</h1>          
  <h2>Artiste Sonore - Ingénieur Son</h2>
  </div>
  {isMobile && (
    <div className="menu-icon" onClick={toggleMenu}>
      <div className={`hamburger ${menuOpen ? 'open' : ''}`}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
        )}
        {isMobile && menuOpen && (
          <div className="sections open">
          <h3 className="clickable" onClick={() => handleTitleClick('presentation')}>Présentation</h3>
          <h3 className="clickable" onClick={() => handleTitleClick('material')}>Matériel</h3>
          <h3 className="clickable" onClick={() => handleTitleClick('experience')}>Expérience</h3>
          <h3 className="clickable" onClick={() => handleTitleClick('sound-examples')}>Exemples sonores</h3>
          <h3 className="clickable" onClick={() => handleTitleClick('media')}>Médias</h3>
        </div>
        )}
        {/* Affichage permanent des titres si l'écran est supérieur à 900px */}
        {!isMobile && (
  <div className="sections"> {/* Enveloppe les h2 dans un div avec la classe 'sections' */}
  <h3 className="clickable" onClick={() => scrollToSection('presentation')}>Présentation</h3>
  <h3 className="clickable" onClick={() => scrollToSection('material')}>Matériel</h3>
  <h3 className="clickable" onClick={() => scrollToSection('experience')}>Expérience</h3>
  <h3 className="clickable" onClick={() => scrollToSection('sound-examples')}>Exemples sonores</h3>
  <h3 className="clickable" onClick={() => scrollToSection('media')}>Média</h3>
</div>
        )}
        <div className="social-icons">
        <a href="mailto:valentin.leverrier@gmail.com">
    <img src="/image/mail.webp" alt="Mail" loading="lazy" />
  </a>
  <a href="tel:+33610381216">
    <img src="/image/phone.webp" alt="Téléphone" loading="lazy" />
  </a>
  <a href="https://www.instagram.com/fousound/" target="_blank" rel="noopener noreferrer">
    <img src="/image/insta.webp" alt="Instagram" loading="lazy" />
  </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;